<template>
  <div>
    <!-- {{fidelizacoes[0]}} -->
    <small>
      * Clique na que deseja renovar
    </small>
    <div class="renovar-fidelizacoes ">
      <div class="fidelizacoes-container">
        <div v-for="fidelizacao of fidelizacoes" :key="fidelizacao.id">
          <fidelizacoes-card
            @click="renovarFidelizacao(fidelizacao)"
            :fidelizacao="fidelizacao"
          ></fidelizacoes-card>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-dependentes"
      size="lg"
      hide-footer
      title="Dependentes do cartão"
    >
      <div v-if="this.fidelizacaoSelecionada">
        <b-table
          :items="fidelizacaoSelecionada.dependentes"
          :fields="[
            { key: 'Nome', label: 'Dependente' },
            { key: 'CPF', label: 'CPF' },
            { key: 'Parentesco', label: 'Parentesco' }
          ]"
        >
          <template #cell(Nome)="row">
            <b-input v-model="row.item.Nome" />
          </template>
          <template #cell(CPF)="row">
            <b-input
              type="text"
              v-model="row.item.CPF"
              v-mask="['###.###.###-##']"
              @change="consultaPorCPF(row.item)"
              @blur="consultaPorCPF(row.item)"
              :state="row.item.isValid"
            />
            <b-form-invalid-feedback>
              CPF Inválido ou Inexistente na base (será cadastrado)
            </b-form-invalid-feedback>
            <small v-if="row.item.exists === false" class="text-warning"
              ><b-icon-info-circle /> CPF não encontado! Será cadastrado!</small
            >
            <small v-if="row.item.equalToTitular === true" class="text-danger"
              ><b-icon-info-circle /> O titular da fidelização não pode ser um
              dependente.</small
            >
            <small v-if="row.item.exists === true" class="text-success"
              ><b-icon-info-circle /> CPF encontado!
              {{ depend.entidadeNome }}</small
            >
          </template>
          <template #cell(Parentesco)="row">
            <b-select
              :options="[
                'Pai/Mãe',
                'Conjuge',
                'Filho/Filha',
                'Avô/Avó',
                'Tio/Tia',
                'Parente',
                row.item.Parentesco
              ]"
              v-model="row.item.Parentesco"
            />
          </template>
        </b-table>
      </div>
    </b-modal>

    <b-modal id="modal-renovar" hide-footer size="lg">
      <template #modal-title>
        <div>
          RENOVANDO O CARTÃO | <b-icon icon="credit-card"></b-icon>
          <b>{{ fidelizacaoSelecionada.codigo }}</b>
        </div>
      </template>
      <renovacao-form
        :fidelizacao="fidelizacaoSelecionada"
        @renovar="onRenovar"
        :tiposFildel="tiposFildel"
      ></renovacao-form>
    </b-modal>
  </div>
</template>

<script>
import Validadores from "../../../helpers/Validadores";
import EntidadesLib from "../../../libs/EntidadesLib";
export default {
  components: {
    FidelizacoesCard: () => import("./FidelizacaoCard.vue"),
    RenovacaoForm: () => import("./RenovacaoForm.vue")
  },
  props: {
    fidelizacoes: Array,
    tiposFildel: Array
  },
  mounted() {
    // console.log(123,this.fidelizacoes);
  },
  data() {
    return {
      fidelizacaoSelecionada: null
    };
  },
  methods: {
    async consultaPorCPF(depend) {
      depend.isValid = null;
      depend.exists = false;
      depend.equalToTitular = false;
      depend.entidade_id = null;
      depend.entidadeNome = null;

      if (
        depend.CPF.length < 13 ||
        !Validadores.string.validarCPF(depend.CPF)
      ) {
        depend.isValid = false;
      } else {
        if (this.titular ? this.titular.cpf !== depend.CPF : true) {
          depend.isValid = true;
          let res = await EntidadesLib.buscaPorNome(depend.CPF);
          if (res) {
            if (res.length <= 0) {
              depend.exists = false;
            } else {
              depend.exists = true;
              depend.entidade_id = res[0].id;
              depend.entidadeNome = res[0].razao_social;
              depend.Nome = res[0].razao_social;
            }
          }
        } else {
          depend.exists = null;
          depend.equalToTitular = true;
        }
      }
      this.$forceUpdate();
    },
    renovarFidelizacao(fidelizacao) {
      if (fidelizacao) {
        console.log("vai renovar: ", fidelizacao);
        this.fidelizacaoSelecionada = Object.assign({}, fidelizacao);

        // this.openFormRenovar();
        this.$bvModal.show("modal-dependentes");
      }
    },
    clickRenovar() {
      this.$emit("renovar", this.fidelizacaoSelecionada);
    },
    openFormRenovar() {
      this.$bvModal.show("modal-renovar");
    },
    closeFormRenovar() {
      this.$bvModal.hide("modal-renovar");
    },
    onRenovar(dados) {
      this.closeFormRenovar();
      this.$emit("renovar", dados);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/fidelizacoes.scss";
</style>
