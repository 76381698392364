var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('small',[_vm._v(" * Clique na que deseja renovar ")]),_c('div',{staticClass:"renovar-fidelizacoes"},[_c('div',{staticClass:"fidelizacoes-container"},_vm._l((_vm.fidelizacoes),function(fidelizacao){return _c('div',{key:fidelizacao.id},[_c('fidelizacoes-card',{attrs:{"fidelizacao":fidelizacao},on:{"click":function($event){return _vm.renovarFidelizacao(fidelizacao)}}})],1)}),0)]),_c('b-modal',{attrs:{"id":"modal-dependentes","size":"lg","hide-footer":"","title":"Dependentes do cartão"}},[(this.fidelizacaoSelecionada)?_c('div',[_c('b-table',{attrs:{"items":_vm.fidelizacaoSelecionada.dependentes,"fields":[
          { key: 'Nome', label: 'Dependente' },
          { key: 'CPF', label: 'CPF' },
          { key: 'Parentesco', label: 'Parentesco' }
        ]},scopedSlots:_vm._u([{key:"cell(Nome)",fn:function(row){return [_c('b-input',{model:{value:(row.item.Nome),callback:function ($$v) {_vm.$set(row.item, "Nome", $$v)},expression:"row.item.Nome"}})]}},{key:"cell(CPF)",fn:function(row){return [_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"type":"text","state":row.item.isValid},on:{"change":function($event){return _vm.consultaPorCPF(row.item)},"blur":function($event){return _vm.consultaPorCPF(row.item)}},model:{value:(row.item.CPF),callback:function ($$v) {_vm.$set(row.item, "CPF", $$v)},expression:"row.item.CPF"}}),_c('b-form-invalid-feedback',[_vm._v(" CPF Inválido ou Inexistente na base (será cadastrado) ")]),(row.item.exists === false)?_c('small',{staticClass:"text-warning"},[_c('b-icon-info-circle'),_vm._v(" CPF não encontado! Será cadastrado!")],1):_vm._e(),(row.item.equalToTitular === true)?_c('small',{staticClass:"text-danger"},[_c('b-icon-info-circle'),_vm._v(" O titular da fidelização não pode ser um dependente.")],1):_vm._e(),(row.item.exists === true)?_c('small',{staticClass:"text-success"},[_c('b-icon-info-circle'),_vm._v(" CPF encontado! "+_vm._s(_vm.depend.entidadeNome))],1):_vm._e()]}},{key:"cell(Parentesco)",fn:function(row){return [_c('b-select',{attrs:{"options":[
              'Pai/Mãe',
              'Conjuge',
              'Filho/Filha',
              'Avô/Avó',
              'Tio/Tia',
              'Parente',
              row.item.Parentesco
            ]},model:{value:(row.item.Parentesco),callback:function ($$v) {_vm.$set(row.item, "Parentesco", $$v)},expression:"row.item.Parentesco"}})]}}],null,false,3858589318)})],1):_vm._e()]),_c('b-modal',{attrs:{"id":"modal-renovar","hide-footer":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',[_vm._v(" RENOVANDO O CARTÃO | "),_c('b-icon',{attrs:{"icon":"credit-card"}}),_c('b',[_vm._v(_vm._s(_vm.fidelizacaoSelecionada.codigo))])],1)]},proxy:true}])},[_c('renovacao-form',{attrs:{"fidelizacao":_vm.fidelizacaoSelecionada,"tiposFildel":_vm.tiposFildel},on:{"renovar":_vm.onRenovar}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }